/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}
/* Declare heights because of positioning of img element */
.carousel-item {
  height: 26rem;
  margin-top: 56px;
  background-color: rgb(110, 99, 172);
  background-repeat: no-repeat;
  background-image: url("/header_one.jpg");
  background-size: cover;
  background-position: center center;
}
.container {
  max-width: 960px;
}
.option {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}
.title:hover {
  color: inherit;
  text-decoration: inherit;
}
.logo {
  height: 40px;
  margin-top: -5px;
  margin-bottom: -5px;
  margin-right: 8px;
}
.logo-main {
  height: 80px;
}
.product {
  padding: 24px;
  min-height: 440px;
}
.product-detail {
  height: 300px;
}
.product-register {
  margin-top: 16px;
}
.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  background-color: #333;
  border-radius: 21px;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.product-device::before {
  position: absolute;
  top: 10%;
  right: 10px;
  bottom: 10%;
  left: 10px;
  content: "";
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}
.product-device-2 {
  top: -25%;
  right: auto;
  bottom: 0;
  left: 5%;
  background-color: #e5e5e5;
}
.text-white {
  h2 {
    color: rgb(255, 255, 255);
  }
}
.logo-client {
  width: 100%;
  height: 225px;
  background-color: #55595c;
  color: #eceeef;
}
.feature {
  cursor: pointer;
  i {
    font-size: 28px;
  }
}
.feature:hover {
  color: var(--warning);
}
.pricing {
  margin-top: 16px;
  margin-bottom: 16px;

  header {
    max-width: 700px;
  }
}
.card-deck .card {
  min-width: 220px;
}
.overflow-hidden {
  overflow: hidden;
}
.jumbotron {
  padding: 6rem;
  margin: 56px 0px 0px 0px;
  color: rgb(255, 255, 255);
  background-image: url("/header_one.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  border-radius: 0px;
}
.jumbotron p {
  font-size: 28px;
  line-height: 38px;
}
.jumbotron-heading {
  font-weight: 300;
}
.jumbotron .container {
  max-width: 50rem;
}
@media (max-width: 576px) {
  #start {
    margin-top: 56px !important;
  }
  .xx-f-z {
    font-size: 42px;
  }
  .carousel-item {
    margin-top: 0px;
    background-image: url("/logoWhite.svg");
    background-size: 90%;
  }
  .jumbotron {
    padding: 2rem;
    background-size: auto 100%;
  }
}
